import { onBeforeUnmount, ref } from '@vue/composition-api'
import * as realtime from '@/infrastructure/realtime/index'

export default function useRealtimeGroups() {
  const groupSubscriptions = ref([])

  /**
   * @param {('FriendItems'|'Unknown')} groupType
   * @param {Array} args
   */
  const subscribeToGroup = async (groupType, args) => {
    let metaConfig
    switch (groupType) {
      case 'FriendItems':
        metaConfig = {
          subscribeServerMethodName: 'SubscribeToUpdatesOfFriendItems',
          subscribeArguments: args,
          unsubscribeServerMethodName: 'UnsubscribeToUpdatesOfFriendItems',
          unsubscribeArguments: args,
        }
        break

      default:
        throw new Error(`Unsupported groupType '${groupType}' in subscribeToGroup`)
    }

    groupSubscriptions.value = [...groupSubscriptions.value, metaConfig]
    await realtime.invokeOnServer(metaConfig.subscribeServerMethodName, args)
  }

  const unsubscribeAll = async () => {
    for (const x of groupSubscriptions.value) {
      await realtime.invokeOnServer(x.unsubscribeServerMethodName, x.unsubscribeArguments)
    }
  }

  onBeforeUnmount(unsubscribeAll)

  return {
    subscribeToGroup,
  }
}
