<script>
import store from '@/infrastructure/store'

export default {
  props: {
    userId: { type: String, required: true },
    fullName: { type: String, required: true },
    email: { type: String, required: true },
    picture: { type: String },
    status: { type: String },

    showActionButtons: { type: Boolean },
    hideShortDescription: { type: Boolean },
  },

  data() {
    return {
      localStatus: this.status,

      isInviting: false,
      isAccepting: false,
      isRejecting: false,
      isUnfriending: false,
    }
  },

  computed: {
    isAcceptedByActor: (that) => that.localStatus === 'AcceptedByActor',
    isAcceptedByByOtherPerson: (that) => that.localStatus === 'AcceptedByOtherPerson',
    isRejectedByActor: (that) => that.localStatus === 'RejectedByActor',
    isWaitingOnOtherPerson: (that) => that.localStatus === 'WaitingOnOtherPerson',

    cardBusy: (that) => that.isInviting || that.isAccepting || that.isRejecting || that.isUnfriending,
  },

  methods: {
    async invite() {
      this.isInviting = true
      try {
        await store.dispatch('friends/createFriendRequest', { userId: this.userId })
        this.localStatus = 'WaitingOnOtherPerson'
      } finally {
        this.isInviting = false
      }
    },
    async accept() {
      this.isAccepting = true
      try {
        await store.dispatch('friends/acceptFriendRequest', { friendUserOrRequestId: this.userId })
        this.localStatus = 'AcceptedByActor'
      } finally {
        this.isAccepting = false
      }
    },
    async reject() {
      this.isRejecting = true
      try {
        await store.dispatch('friends/rejectFriendRequest', { friendUserOrRequestId: this.userId })
        this.localStatus = 'RejectedByActor'
        this.$emit('rejected')
      } finally {
        this.isRejecting = false
      }
    },
    async unfriend() {
      this.isUnfriending = true
      try {
        await store.dispatch('friends/unfriendFriendRequest', { friendUserOrRequestId: this.userId })
        this.localStatus = ''

        // This was only used in FriendWithItems but never fired because the 'friend' object gets implicitly
        // removed when fetchAllFriendRequests is called store friends/unfriendFriendRequest
        // this.$emit('unfriended')
      } finally {
        this.isUnfriending = false
      }
    },
  },
}
</script>

<template>
  <v-card v-bind="$attrs" v-on="$listeners" :loading="cardBusy || $attrs.loading">
    <div class="d-flex flex-no-wrap align-center justify-space-between">
      <div>
        <v-card-title v-text="fullName"></v-card-title>
        <v-card-subtitle v-text="email" style="word-break: break-all"></v-card-subtitle>
      </div>
      <v-avatar class="ma-3" size="60" tile>
        <v-img v-if="picture" :src="picture"></v-img>
      </v-avatar>
    </div>

    <v-expand-transition>
      <div v-if="showActionButtons">
        <v-card-text class="py-0">
          <div v-if="!localStatus">
            <v-btn block x-large text color="success" :loading="isInviting" :disabled="isInviting" @click="invite">
              <v-icon left>mdi-send</v-icon>
              Send friend request
            </v-btn>
          </div>
          <div v-else>
            <div v-if="!hideShortDescription">
              <div v-if="!isAcceptedByActor && !isAcceptedByByOtherPerson && !isRejectedByActor && !isWaitingOnOtherPerson">
                You have a friend request from {{ fullName }}
              </div>
              <div v-else-if="isAcceptedByActor || isAcceptedByByOtherPerson" class="success--text">
                <v-icon small color="success">mdi-check</v-icon>
                You are friends
              </div>
              <div v-else-if="isRejectedByActor" class="error--text">
                <v-icon small color="error">mdi-cancel</v-icon>
                You have rejected their friend request
              </div>
              <div v-else-if="isWaitingOnOtherPerson" class="grey--text">
                <v-icon small>mdi-send</v-icon>
                Request sent
              </div>
            </div>

            <v-row v-if="!isAcceptedByByOtherPerson && !isWaitingOnOtherPerson" no-gutters>
              <v-col cols="6">
                <v-btn block x-large text color="success" :loading="isAccepting" :disabled="isAccepting || isAcceptedByActor" class="mt-3" @click="accept">
                  <v-icon left>mdi-account-check-outline</v-icon>
                  Accept
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block x-large text color="error" :loading="isRejecting" :disabled="isRejecting || isRejectedByActor" class="mt-3" @click="reject">
                  <v-icon left>mdi-account-cancel-outline</v-icon>
                  Reject
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="isAcceptedByByOtherPerson || isWaitingOnOtherPerson" no-gutters>
              <v-col cols="6" offset="3">
                <v-btn block x-large text color="error" :loading="isUnfriending" :disabled="isUnfriending" class="mt-3" @click="unfriend">
                  <v-icon left>mdi-account-cancel-outline</v-icon>
                  Unfriend
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

    <slot name="footer" />
  </v-card>
</template>
